import React from 'react'
import { Provider } from 'react-redux'
import initializeStore from 'state/initializeStore'
import LocalStorage from 'lib/localStorage'
import { APP_THEMES_LIGHT, APP_THEMES_DARK, LIST_ORDER_BY_CREATED } from 'lib/constants'

const Wrapper = (isBrowser = false) => ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const prepareStoreData = () => {
    if (isBrowser) {
      let theme = LocalStorage.getItem('theme')

      if (!theme) {
        if (window.matchMedia('(prefers-color-scheme: dark)')?.matches) {
          theme = APP_THEMES_DARK
        }
      }

      return {
        settings: {
          theme: theme || APP_THEMES_LIGHT
        },
        links: {
          listOrderBy: LocalStorage.getItem('orderBy') || LIST_ORDER_BY_CREATED
        }
      }
    }
    return {}
  }

  const store = initializeStore(prepareStoreData())

  return (
    <Provider store={store}>{element}</Provider>
  )
}

export default Wrapper
