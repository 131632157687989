import { put, call, select, takeLatest } from 'redux-saga/effects'
import {
  getLinksAPI,
  addLinkAPI,
  editLinkAPI,
  removeLinkAPI
} from 'httpApi/links'
import { getUser } from 'selectors/auth'
import { getOrderBy, getFilterBySlug } from 'selectors/links'
import {
  fetchLinksRequest,
  fetchLinksSuccess,
  fetchLinksFailure,
  addLinkRequest,
  addLinkSuccess,
  addLinkFailure,
  editLinkRequest,
  editLinkSuccess,
  editLinkFailure,
  removeLinkRequest,
  removeLinkSuccess,
  removeLinkFailure,
  filterLinksBySlug,
  changeLinksOrderBy
} from 'actions/links'
import { showBackendErrors } from './helpers'

export const fetchLinks = function* () {
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential
      const filterBySlug = yield select(getFilterBySlug)
      const orderBy = yield select(getOrderBy)

      const response = yield call(getLinksAPI, token, {
        filter_by_slug: filterBySlug,
        order_by: orderBy
      })
      yield put(fetchLinksSuccess({ response }))
    } else {
      yield put(fetchLinksFailure(true))
    }
  } catch (error) {
    yield put(fetchLinksFailure(error))
  }
}

export const addLink = function* ({ payload }) {
  const { data, resolve, reject } = payload
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(addLinkAPI, token, data)
      yield put(addLinkSuccess({ response }))
      yield call(resolve, response.data)
    } else {
      yield put(addLinkFailure(true))
    }
  } catch (error) {
    yield put(addLinkFailure(error))
    yield call(showBackendErrors, error, reject)
  }
}

export const editLink = function* ({ payload }) {
  const { id, data, resolve, reject } = payload
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(editLinkAPI, token, id, data)

      yield put(editLinkSuccess({ response, id }))
      yield call(resolve, response.data)
    } else {
      yield put(editLinkFailure(true))
    }
  } catch (error) {
    yield put(editLinkFailure(error))
    yield call(showBackendErrors, error, reject)
  }
}

export const removeLink = function* ({ payload }) {
  const { id } = payload
  try {
    const user = yield select(getUser)

    if (user) {
      const token = user.response.credential

      const response = yield call(removeLinkAPI, token, id)
      yield put(removeLinkSuccess({ response, id }))
    } else {
      yield put(removeLinkFailure(true))
    }
  } catch (error) {
    yield put(removeLinkFailure(error))
  }
}

export const watch = function* () {
  yield takeLatest(fetchLinksRequest, fetchLinks)
  yield takeLatest(filterLinksBySlug, fetchLinks)
  yield takeLatest(changeLinksOrderBy, fetchLinks)
  yield takeLatest(addLinkRequest, addLink)
  yield takeLatest(editLinkRequest, editLink)
  yield takeLatest(removeLinkRequest, removeLink)
}
