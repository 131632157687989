import { combineReducers } from 'redux'
import { createReducer } from 'redux-act'
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
  hideFlashMessage
} from 'actions/flashMessage'
import {
  addLinkSuccess,
  editLinkSuccess,
  removeLinkSuccess
} from 'actions/links'

// message types
const successMessageType = (message) => ({ message, type: 'success' })
const errorMessageType = (message) => ({ message, type: 'error' })

const flashMessageData = createReducer(
  {
    // basic
    [showSuccessFlashMessage]: (_state, payload) => successMessageType(payload),
    [showErrorFlashMessage]: (_state, payload) => errorMessageType(payload),
    // actions
    [addLinkSuccess]: () => successMessageType('Link successfully added'),
    [editLinkSuccess]: () => successMessageType('Link successfully saved'),
    [removeLinkSuccess]: () => successMessageType('Link successfully removed'),
    [hideFlashMessage]: () => ({}),
  },
  {}
)

export const reducer = combineReducers({
  data: flashMessageData
})
