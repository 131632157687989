import { IS_DEV } from 'lib/constants'

export const BASE_ENDPOINT = (
  IS_DEV ? 'http://localhost:8080/api' : '/api'
)

export const DEFAULT_OPTIONS = {
  timeout: 30000,
  responseType: 'json',
  responseEncoding: 'utf8',
  withCredentials: true
}

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
}

export const requestHeaders = (idToken) => ({
  ...DEFAULT_HEADERS,
  Authorization: `Bearer ${idToken}`
})
