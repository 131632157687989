import { createSelector } from 'reselect'
import { getUser } from './auth'

export const getIDsList = ({ links }) => links.list
export const getLinksById = ({ links }) => links.byId
export const getOrderBy = ({ links }) => links.listOrderBy
export const getFilterBySlug = ({ links }) => links.listFilterBy

export const getLinks = createSelector(
  [getUser, getIDsList, getLinksById],
  (user, allIds, byId) => allIds.map((id) => ({
    ...byId[id],
    owners: (byId[id]?.owners || []).filter((email) => email !== user.info.email)
  }))
)
