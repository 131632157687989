export const IS_DEV = process.env.NODE_ENV === 'development'

export const GOOGLE_HOSTED_DOMAIN = 'railsware.com'
export const GOOGLE_AUTH_SCOPES = [
  'openid',
  'email',
  'profile'
]

export const APP_THEMES_LIGHT = 'light'
export const APP_THEMES_DARK = 'dark'

export const LIST_ORDER_BY_CREATED = 'created_at'
export const LIST_ORDER_BY_VISIT_COUNT = 'visit_count'
export const LIST_ORDER_BY_SLUG = 'slug'
