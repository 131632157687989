import { createAction } from 'redux-act'

export const fetchLinksRequest = createAction('Request to fetch links')
export const fetchLinksSuccess = createAction('Success in fetching links')
export const fetchLinksFailure = createAction('Failure in fetching links')

export const addLinkRequest = createAction('Request to add link')
export const addLinkSuccess = createAction('Success in add link')
export const addLinkFailure = createAction('Failure in add link')

export const editLinkRequest = createAction('Request to edit link')
export const editLinkSuccess = createAction('Success in edit link')
export const editLinkFailure = createAction('Failure in edit link')

export const removeLinkRequest = createAction('Request to remove link')
export const removeLinkSuccess = createAction('Success in remove link')
export const removeLinkFailure = createAction('Failure in remove link')

export const filterLinksBySlug = createAction('Filter by slug')
export const changeLinksOrderBy = createAction('Change links order')
