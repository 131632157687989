import axios from 'axios'
import { BASE_ENDPOINT, DEFAULT_OPTIONS, requestHeaders } from './instance'

export const getLinksAPI = (idToken, params = {}) => {
  return axios({
    ...DEFAULT_OPTIONS,
    params,
    method: 'get',
    url: `${BASE_ENDPOINT}/links`,
    headers: requestHeaders(idToken)
  })
}

export const addLinkAPI = (idToken, data = {}) => {
  return axios({
    ...DEFAULT_OPTIONS,
    data,
    method: 'post',
    url: `${BASE_ENDPOINT}/links`,
    headers: requestHeaders(idToken)
  })
}

export const editLinkAPI = (idToken, id, data = {}) => {
  return axios({
    ...DEFAULT_OPTIONS,
    data,
    method: 'patch',
    url: `${BASE_ENDPOINT}/links/${id}`,
    headers: requestHeaders(idToken)
  })
}

export const removeLinkAPI = (idToken, id) => {
  return axios({
    ...DEFAULT_OPTIONS,
    method: 'delete',
    url: `${BASE_ENDPOINT}/links/${id}`,
    headers: requestHeaders(idToken)
  })
}
