import { combineReducers } from 'redux'
import { createReducer } from 'redux-act'
import { settingsToggleTheme } from 'actions/settings'
import {
  APP_THEMES_LIGHT,
  APP_THEMES_DARK
} from 'lib/constants'

const theme = createReducer({
  [settingsToggleTheme]: (state) => (
    APP_THEMES_LIGHT === state ? APP_THEMES_DARK : APP_THEMES_LIGHT
  )
}, APP_THEMES_LIGHT)

export const reducer = combineReducers({
  theme
})
