import { combineReducers } from 'redux'
import { createReducer } from 'redux-act'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import {
  fetchLinksRequest,
  fetchLinksSuccess,
  fetchLinksFailure,
  addLinkSuccess,
  editLinkSuccess,
  removeLinkSuccess,
  changeLinksOrderBy,
  filterLinksBySlug
} from 'actions/links'
import {
  LIST_ORDER_BY_CREATED,
  LIST_ORDER_BY_SLUG
} from 'lib/constants'

const list = createReducer(
  {
    [fetchLinksSuccess]: (_state, { response: { data } }) => data.map((l) => l.id),
    [addLinkSuccess]: (state, { response: { data } }) => [data.id, ...state],
    [removeLinkSuccess]: (state, { id }) => state.filter((linkId) => linkId !== id)
  },
  []
)

const byId = createReducer(
  {
    [fetchLinksSuccess]: (state, { response: { data } }) => ({
      ...state,
      ..._keyBy(data, 'id')
    }),
    [addLinkSuccess]: (state, { response: { data } }) => ({
      ...state,
      [data.id]: data
    }),
    [editLinkSuccess]: (state, { response: { data } }) => ({
      ...state,
      [data.id]: data
    }),
    [removeLinkSuccess]: (state, { id }) => _omit(state, [id])
  },
  {}
)

const isListLoading = createReducer(
  {
    [fetchLinksRequest]: () => true,
    [fetchLinksSuccess]: () => false,
    [fetchLinksFailure]: () => false
  },
  false
)

const listLoadError = createReducer(
  {
    [fetchLinksRequest]: () => null,
    [fetchLinksSuccess]: () => null,
    [fetchLinksFailure]: (_state, payload) => payload
  },
  null
)

const listFilterBy = createReducer(
  {
    [filterLinksBySlug]: (_state, payload) => payload,
    [changeLinksOrderBy]: () => ''
  },
  ''
)

const listOrderBy = createReducer(
  {
    [filterLinksBySlug]: () => LIST_ORDER_BY_SLUG,
    [changeLinksOrderBy]: (_state, payload) => payload
  },
  LIST_ORDER_BY_CREATED
)

export const reducer = combineReducers({
  list,
  byId,
  isListLoading,
  listLoadError,
  listOrderBy,
  listFilterBy
})
