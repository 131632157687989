import { put, call } from 'redux-saga/effects'
import { showErrorFlashMessage } from 'actions/flashMessage'

const submitFormErrors = (errors = {}) =>
  Object.keys(errors).reduce((arr, key) => ({ ...arr, [key]: errors[key].join(', ') }), {})

export const showBackendErrors = function* (error, reject) {
  const errorData = error.response?.data || {}
  const normalizedErrors = yield call(submitFormErrors, errorData.errors)
  const { base, ...restErrors } = normalizedErrors
  if (base) {
    yield put(showErrorFlashMessage(base))
  }
  if (reject) {
    yield call(reject, restErrors)
  }
}
