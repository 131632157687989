import { combineReducers } from 'redux'

import { reducer as auth } from 'reducers/auth'
import { reducer as flashMessage } from 'reducers/flashMessage'
import { reducer as links } from 'reducers/links'
import { reducer as settings } from 'reducers/settings'

export default combineReducers({
  auth,
  flashMessage,
  links,
  settings
})
