module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-576c0392f0/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-72462d7503.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RW Shortener","short_name":"RW Shortener","start_url":"/","background_color":"#f9f9f9","theme_color":"#64c5b1","display":"standalone","icon":"icon.svg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-cacfdb7f8f/0/cache/gatsby-plugin-offline-npm-6.14.0-87805d241e-4be494daf0.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"cdn","globPatterns":["**/icons*"],"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-e8ce081aa8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
