import { all, fork } from 'redux-saga/effects'

import { watch as watchLinks } from './links'

const rootSaga = function* () {
  yield all([
    fork(watchLinks)
  ])
}

export default rootSaga
